.section-title {
  font-size: 39px;
  text-align: center;
  margin-top: 65px;
  margin-bottom: 90px;
}

.our-rooms {
margin-top: 100px !important;
}

.description-content {
text-align: center;
font-size: 1.5rem;
font-weight: bold;
}

.property-description {
margin-top: 65px;
margin-bottom: 40px !important;
}

.is-selected {
opacity: 1 !important;
}

.attributes-container {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0 0;
}

.attributes-container li {
  display: flex;
  flex-direction: column;
  float: left;
  justify-content: center;
  text-align: center;
  width: 50%;
  border: 1px solid #efefef;
  list-style-type: none;
  position: relative;
  margin: 0 -1px -1px 0;
  padding: 17px 30px 18px;
}

.highlight-container {
  margin-left: -25px !important;
  display: flex;
  justify-content: center;
  margin-top: -12px;
}

.highlight-container li {
  list-style: none;
  /*width: 100px;*/
  font-size: 1rem;
}

.property-content{
  margin-top: 100px;
}

.property-attributes{
  flex-grow: 1;
}

.custom-slider{
  display: block;
}

.custom-slider .flickity-viewport{
  height: 500px !important;
}

.responsive-slider{
border-bottom: 1px solid #ccc;
background-color: #fff;
display: none;
height: 0;
padding-bottom: 74%;
position: relative;
width: 100%;
}

.responsive-slider .slider{
height: 100%;
width: 100%;
position: absolute;
right: 0;
top: 0;
}

.custom-property-section{
display: block;
}

.responsive-property-section{
display: none !important;
}

@media (min-width: 2200px){
  .custom-slider .flickity-viewport{
    height: 720px !important;
  }
} 

@media (max-width: 1200px){

  .section-title {
    font-size: 26px !important;
    margin-top: 35px !important;
    margin-bottom: 45px !important;
  }

  .responsive-slider{
    display: block;
  }

  .custom-slider{
    display: none;
  }

  .custom-property-section{
    display: none !important;
  }

  .responsive-property-section{
    display: block !important;
  }

  .property-content {
    margin-top: 50px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .attributes-container {
    margin-left: -25px !important;
  }
}