.flatpickr-input {
  display: none !important;
}
.flatpickr-calendar.inline {
  margin: auto;
  margin-top: 28px;
}
.guest-button,
.guest-button:active,
.guest-button:hover,
.guest-button:visited {
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: block;
  padding-top: 7px;
  padding-left: 14px;
  color: #565656;
  font-weight: 600;
  cursor: pointer;
  font-size: 20px;
}
.guest-button-right {
  margin-left: 10px !important;
  padding-left: 12px !important;
}
.guest-button-disabled {
  border: 1px solid #e4e4e4 !important;
  color: #e4e4e4 !important;
}
.guest-butttons-container {
  display: flex !important;
}
.adults {
  font-size: 24px;
  margin-top: 8px;
}
.guest-tittle {
  font-size: 27px;
  margin-top: 35px;
  margin-bottom: 35px;
}
.guest-section {
  padding-top: 25px !important;
}
.resorts {
  font-size: 1.5rem;
  padding: 25px !important;
  border-right: 1px solid #ccc !important;
  padding-left: 60px !important;
}
.resorts-results {
  padding: 60px !important;
}
.resort-item {
  font-size: 20px;
  margin-bottom: 6px;
  color: #838383;
  cursor: pointer;
}
.resort-item-selected {
  color: #000;
}

.search-dimmer {
  left: 0;
  width: 100% !important;
  height: 350px;
  z-index: 9;
  position: fixed;
  transform: translateY(138%);
  transition: transform 0.75s cubic-bezier(0.23, 1, 0.32, 1);
  background-color: #fff;
  z-index: 999;
  bottom: 559px;
  opacity: 0.7;
  display: none !important;
}

.search-dimmer-active {
  display: block !important;
}

.calendar-dimmer {
  left: 0;
  width: 100% !important;
  height: 316px;
  z-index: 9;
  position: absolute;
  transform: translateY(138%);
  transition: transform 0.75s cubic-bezier(0.23, 1, 0.32, 1);
  background-color: #fff;
  z-index: 999;
  bottom: 434px;
  opacity: 0.7;
  display: none;
}

.calendar-dimmer-active {
  display: block !important;
}

.calendar-dimmer-form {
  margin: 0;
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
  left: 50%;
}

.book-responsive,
.book-responsive:hover,
.book-responsive:visited,
.book-responsive:focus {
  display: none;
  position: fixed !important;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: #000;
  color: #fff;
  height: 75px;
  padding: 25px;
  font-size: 17px;
  z-index: 999;
}

.result-content {
  overflow: scroll;
  height: 92%;
  max-width: 1920px;
  margin: 0 auto 60px;
  max-width: 1920px;
  position: relative;
  width: 100%;
  overflow-x: hidden;
}

.result-available-property {
  font-size: 1.4rem;
  padding-left: 1.5rem;
}

.result-content-back-responsive,
.result-content-back-responsive:hover,
.result-content-back-responsive:focus {
  display: none;
  /* width: 100%; 
  text-align: left; */
  margin-top: 0px;
  margin-bottom: 10px;
  color: #000;
  margin-left: 28px;
}

.search-button {
  background-color: #000;
  color: #fff;
  height: 74px;
  padding-top: 28px !important;
  cursor: pointer;
}

.result-content::-webkit-scrollbar {
  display: none;
}

.search-form {
  border-bottom: 1px solid #ccc !important;
  height: 75px !important;
}

.back-button,
.back-button:hover {
  color: #000;
  margin-top: -17px;
  display: block;
  margin-bottom: 32px;
  cursor: pointer;
}

.remove-roomtype-button,
.remove-roomtype-button:hover,
.remove-roomtype-button:focus {
  color: #888;
  font-size: 13px;
}

.flatpickr-day .calendar-date {
  position: relative;
  top: -7px;
  color: #404848;
}

.flatpickr-day .rate {
  font-size: 10px;
  position: absolute;
  top: calc(50% - 10px);
  right: calc(25%);
  color: #00a633;
  height: 0;
}

.notAllowed .rate {
  font-size: 10px;
  position: absolute;
  top: calc(50% - 10px);
  right: calc(25%);
  color: #00a633;
  height: 0;
}

.flatpickr-disabled .rate {
  cursor: not-allowed !important;
  color: rgba(64, 72, 72, 0.1) !important;
}

.flatpickr-disabled .calendar-date {
  cursor: not-allowed !important;
  color: rgba(64, 72, 72, 0.1) !important;
}

.flatpickr-disabled {
  cursor: not-allowed !important;
  color: rgba(64, 72, 72, 0.1) !important;
}

.flatpickr-day.selected .rate,
.flatpickr-day.startRange .rate,
.flatpickr-day.endRange .rate,
.flatpickr-day.selected.inRange .rate,
.flatpickr-day.startRange.inRange .rate,
.flatpickr-day.endRange.inRange .rate {
  color: #2fff6f !important;
}

.roomtype-responsive {
  font-size: 1.5rem;
}

.roomtype-responsive-grid {
  margin-bottom: -35px !important;
}

.check-out-grid-content {
  display: block;
  height: calc(100% - 76px);
}

.view-all-rooms-link,
.view-all-rooms-link:hover {
  color: #000;
  font-size: 21px;
  cursor: pointer;
  position: absolute;
  top: 330px;
}

.flatpickr-calendar {
  -webkit-box-shadow: 1px 0 0 #eee, -1px 0 0 #eee, 0 1px 0 #eee, 0 -1px 0 #eee,
    0 0px 0px rgb(0 0 0 / 8%) !important;
  box-shadow: 1px 0 0 #eee, -1px 0 0 #eee, 0 1px 0 #eee, 0 -1px 0 #eee, 0 0px 0px rgb(0 0 0 / 8%) !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  font-weight: 500 !important;
}

span.flatpickr-weekday {
  font-weight: 400;
  color: #000 !important;
}

.flatpickr-calendar {
  width: 370px !important;
}

.flatpickr-days {
  width: 370px !important;
}

.dayContainer {
  width: 370px;
  min-width: 370px;
  max-width: 370px;
}

.sort-select {
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  margin-right: 1.5rem;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.search-property-name-large {
  position: absolute;
  top: 18px;
  left: 46px;
}

.search-property-name-short {
  position: absolute;
}

.cart-icon {
  margin-left: 20px !important;
  margin-right: 15px !important;
  font-size: 17px !important;
}

@media (max-width: 1200px) {
  .search-container {
    display: none !important;
  }
  .book-responsive {
    display: block !important;
  }
  .guest-button-right {
    position: absolute;
    right: 15px;
    padding-right: 12px;
  }
  .guest-button-left {
    position: absolute;
    right: 65px;
    padding-right: 15px;
  }
  .dates-content {
    border: 1px solid #818181;
    margin-top: 18px;
    padding: 15px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .search-content {
    position: absolute;
    bottom: 280px;
    width: 93% !important;
  }

  .result-content {
    margin-right: 0px !important;
    height: 100% !important;
  }

  .result-content-back-responsive {
    display: block !important;
  }

  .flatpickr-calendar {
    width: 330px !important;
  }

  .flatpickr-days {
    width: 330px !important;
  }

  .dayContainer {
    width: 330px;
    min-width: 330px;
    max-width: 330px;
  }

  .cart-icon {
    display: none !important;
  }
}
