.roomtype-preview{
    height: 100%;
    font-family: Canela-Regular, serif;
}

.roomtype-preview-head{
    height: 76px;
    width: 100%;
    background: #000;
    color: #fff;
    padding: 27px;
}

.roomtype-preview-body{
    padding: 40px;
    padding-left: 0px;
    padding-right: 0px;
}

.roomtype-preview-body .property{
    text-align: center;
    font-family: Work-Sans,sans-serif;
}

.roomtype-preview-body .roomtype{
    text-align: center;
    font-size: 54px;
    margin-top: 30px;
}

.roomtype-preview-body .slider{
    margin-top: 50px;
}

.roomtype-preview-body .sleeps{
    text-align: center;
    font-size: 11px;
    margin-top: 40px;
    font-family: Work-Sans,sans-serif;
}

.roomtype-preview-body .flickity-page-dots{
    display: none !important;
}

.roomtype-preview-close, .roomtype-preview-close:hover{
    color: #fff;
    cursor: pointer;
    font-family: Work-Sans,sans-serif;
    font-size: 14px;
}

.roomtype-preview-rate{
    font-size: 21px;
}

.slider-responsive{
    display: none;
}

.preview-slider{
    margin-top: 50px;
}

@media (max-width: 1200px){
    .preview-slider{
        display: none !important;
    }
    .slider-responsive{
        display: block !important;
    }
    .sleeps{
        margin-top: 70px !important;
    }
    .roomtype-preview-body .roomtype{
        font-size: 24px !important;
    }
    .roomtype-preview-rate{
        font-size: 17px !important;
    }
    .roomtype-preview-close, .roomtype-preview-close:hover {
        font-size: 11px !important;
    }
}