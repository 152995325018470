html {
  font-size: 14px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Work Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  scroll-behavior: smooth;
}

#full-screen-loader {
  display: block;
}

#card-element {
  margin: 0;
  outline: 0;
  -webkit-appearance: none;
  tap-highlight-color: rgba(255, 255, 255, 0);
  line-height: 1.21428571em;
  padding: 0.67857143em 1em;
  font-size: 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  box-shadow: 0 0 0 0 transparent inset;
  transition: color 0.1s ease, border-color 0.1s ease;
}

.flickity-slider .is-selected .dimmer {
  display: flex !important;
  width: 100% !important;
  visibility: visible !important;
}

.flickity-slider .flickity-bg-lazyloaded.is-selected .dimmer,
.flickity-slider .is-selected .flickity-bg-lazyloaded .dimmer,
.flickity-slider .dimmer {
  display: none !important;
  width: 0% !important;
  visibility: hidden !important;
}

.dimmer {
  position: fixed;
  left: 0;
  top: 0;
  background-color: #fff;
  width: 100%;
  height: 100%;
  z-index: 9999999999;
}

.full-screen-loader {
  display: block !important;
}
