.checkout-grid {
  margin-top: 1px !important;
  height: 100%;
}

.checkout-grid-left {
  border: 1px solid #979797;
  padding: 75px !important;
  padding-top: 0px !important;
  border-top: none !important;
}

.checkout-grid-right {
  padding: 75px !important;
  padding-top: 0px !important;
  overflow-y: scroll !important;
  height: calc(100% - 185px) !important;
}

.checkout-grid-tittle {
  margin-top: 30px !important;
  font-weight: 100;
}

@media (max-width: 1200px) {
  .checkout-grid-tittle {
    margin-top: 0px !important;
  }
}

@media (max-width: 1200px) {
  .checkout-grid-left {
    width: 100% !important;
    padding: 45px !important;
  }

  .checkout-grid-right {
    width: 100% !important;
    padding: 45px !important;
  }

  .checkout-grid {
    overflow-y: scroll;
  }
}

@media (max-width: 600px) {
  .checkout-grid-left {
    width: 100% !important;
    padding: 20px !important;
  }

  .checkout-grid-right {
    width: 100% !important;
    padding: 20px !important;
    padding-left: 35px !important;
  }

  .checkout-grid {
    overflow-y: scroll;
  }
}
