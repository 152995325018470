.vroomvroom {
  height: 100%;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  width: 100%;
  z-index: 100000;
}
.vroomvroom .set-color {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.vroomvroom .set-color .color {
  background-color: #fed5d7;
  height: 100%;
  position: absolute;
  right: 50%;
  top: 0;
  width: 0;
}
.vroomvroom .set-color .color:last-child {
  right: 0;
}
.vroomvroom .cars {
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  -webkit-transform: translateX(-25%);
  transform: translateX(-25%);
  width: 100%;
  z-index: 2;
}
.vroomvroom .cars img {
  display: none;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.vroomvroom .cars img.active {
  display: block;
}
.vroomvroom.running {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.vroomvroom.running .set-color .color {
  -webkit-transition: width 500ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: width 500ms cubic-bezier(0.77, 0, 0.175, 1);
  width: 50%;
}
.vroomvroom.running .cars {
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: transform 1000ms cubic-bezier(0.165, 0.84, 0.44, 1) 500ms,
    opacity 1000ms ease-in-out 500ms;
  transition: transform 1000ms cubic-bezier(0.165, 0.84, 0.44, 1) 500ms,
    opacity 1000ms ease-in-out 500ms;
}
.vroomvroom.stall {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.vroomvroom.stall .set-color .color {
  width: 50%;
}
.vroomvroom.stall .cars {
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.vroomvroom.drive {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.vroomvroom.drive .set-color .color {
  -webkit-transition: width 500ms cubic-bezier(0.77, 0, 0.175, 1) 1000ms;
  transition: width 500ms cubic-bezier(0.77, 0, 0.175, 1) 1000ms;
  width: 0;
}
.vroomvroom.drive .cars {
  opacity: 0;
  -webkit-transform: translateX(25%);
  transform: translateX(25%);
  -webkit-transition: transform 1000ms cubic-bezier(0.895, 0.03, 0.685, 0.22),
    opacity 1000ms ease-in-out;
  transition: transform 1000ms cubic-bezier(0.895, 0.03, 0.685, 0.22), opacity 1000ms ease-in-out;
}

.thank-you-wrapper {
  margin: 2rem 0 0;
}
.thank-you-wrapper .two-col {
  position: relative;
  width: 100%;
}
.thank-you-wrapper .two-col.set-height {
  height: -webkit-calc(100vh - 124px);
  height: calc(100vh - 124px);
}
@media (max-width: 767px) {
  .thank-you-wrapper .two-col.set-height {
    height: auto;
  }
}
.thank-you-wrapper .two-col .block {
  display: block;
  float: left;
  height: 100%;
  margin: 0 0 0 30px;
  position: relative;
  width: -webkit-calc(50% - 30px);
  width: calc(50% - 30px);
}

@media (max-width: 1024px) {
  .thank-you-wrapper .two-col .block {
    margin: 0 15px 0 0;
    width: -webkit-calc(50% - 15px);
    width: calc(50% - 15px);
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .thank-you-wrapper .two-col .block:last-child {
    margin: 0 0 0 15px;
  }
}
@media (max-width: 767px) {
  .thank-you-wrapper .two-col .block {
    float: none;
    margin: 0 !important;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.thank-you-wrapper .confirmation-num {
  /*font-family: 'Work-Sans', sans-serif;*/
  font-size: 1.2rem;
  line-height: 1.5;
  margin: 0;
}
.thank-you-wrapper .headline,
.thank-you-wrapper .headlineInformation {
  color: #747474;
  /*font-family: 'Work-Sans', sans-serif;*/
  font-size: 1rem;
  letter-spacing: 1.4px;
  line-height: normal;
  margin: 0;
  text-transform: uppercase;
}

.thank-you-wrapper .headlineInformation {
  margin-bottom: 10px;
}

.thank-you-wrapper h1.headlineDescription {
  color: #333333;
  /*font-family: 'Canela-Regular', serif;*/
  font-size: 1.5rem;
  line-height: 1.14;
  margin: 10px 0 30px;
}

.thank-you-wrapper .break {
  background-color: #000000;
  height: 1px;
  margin: 1rem 0;
  position: relative;
  width: 100%;
}
.thank-you-wrapper .policy {
  color: #747474;
  /*font-family: 'Work-Sans', sans-serif;*/
  font-size: 1.3rem;
  line-height: 1.54;
  margin: 0 0 30px;
}
.thank-you-wrapper .policy strong {
  font-weight: bold;
}
.thank-you-wrapper a:hover {
  text-decoration: underline;
}
.thank-you-wrapper .remove-item {
  display: none !important;
}
.thank-you-wrapper .cart-items-wrapper {
  position: relative;
}
.thank-you-wrapper .cart-items-wrapper .item {
  background-color: #ffffff;
  border: 1px solid #979797;
  margin: 60px 0 0;
  padding: 60px;
  position: relative;
}
@media (max-width: 1024px) {
  .thank-you-wrapper .cart-items-wrapper .item {
    padding: 30px;
  }
}
@media (max-width: 768px) {
  .thank-you-wrapper .cart-items-wrapper .item {
    border: 0 none;
    border-top: 1px solid #979797;
    padding: 50px 30px;
  }
}
.thank-you-wrapper .cart-items-wrapper .item:first-child {
  margin: 0;
}
.thank-you-wrapper .cart-items-wrapper .item .location {
  color: #747474;
  display: block;
  /*font-family: 'Work-Sans', sans-serif;*/
  font-size: 1.1rem;
  letter-spacing: 1.4px;
  line-height: normal;
  margin: 0 0 8px;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .thank-you-wrapper .cart-items-wrapper .item .location {
    color: #019176;
  }
}
.thank-you-wrapper .cart-items-wrapper .item h1 {
  display: block;
  /*font-family: 'Canela-Regular', serif;*/
  font-size: 2rem;
  line-height: 1.5;
  margin: 0;
}
.thank-you-wrapper .cart-items-wrapper .item .dates {
  display: block;
  /*font-family: 'Work-Sans', sans-serif;*/
  font-size: 1.3rem;
  line-height: normal;
  margin: 26px 0 0;
}
.thank-you-wrapper .cart-items-wrapper .item .summary-toggle {
  display: none;
}
.thank-you-wrapper .cart-items-wrapper .item .summary-toggle-checkout {
  cursor: pointer;
  display: inline-block;
  /*font-family: 'Work-Sans', sans-serif;*/
  font-size: 1.3rem;
  margin: 26px 0 0;
  position: relative;
  text-decoration: underline;
  width: 100%;
}
.thank-you-wrapper .cart-items-wrapper .item .summary-toggle-checkout span {
  display: inline-block;
  position: absolute;
  right: 0;
  text-decoration: none;
  top: 0;
}
.thank-you-wrapper .cart-items-wrapper .item .summary {
  display: block;
}
.thank-you-wrapper .cart-items-wrapper .item .summary p {
  display: block;
  /*font-family: 'Work-Sans', sans-serif;*/
  font-size: 1.3rem;
  line-height: normal;
  margin: 30px 0 0;
  position: relative;
}
.thank-you-wrapper .cart-items-wrapper .item .summary p span {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
}

.thank-you-wrapper .two-col .block .scrollable {
  height: calc(100vh - 200px);
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.thank-you-wrapper .two-col .block .scrollable::-webkit-scrollbar {
  display: none;
}
