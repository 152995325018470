.footer {
  height: 700px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.info-left{
  padding-right: 100px !important;
}

.info-right{
  padding-left: 100px !important;
}

.footer-info{
  padding-top: 580px;
  text-align: center;
  color: #fff;
  font-size: 17px;  
  margin-top: -75px;
}

.footer-info-content{
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;  
  font-size: 15x;
}    

.footer-info-content-1{
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;  
  font-size: 15px;
  margin-top: 39px;
}

.footer-company-info{
  margin-top: 7px;
  margin-left: 12px;
}

.custom-info-panel{
  display: block;
  text-align: center;
}

.responsive-info-panel{
  display: none !important;
}

.top-button, .top-button:hover{
  display: block;
  margin: 0 auto;
  position: relative;
  top: -37.5px;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #747474;
  cursor: pointer;
  padding: 27px;
  font-size: 17px;
  color: #747474;
}

.footer-without-image .top-button{
  top: -65.5px !important;
}

.footer-without-image .footer-info-content-1{
  margin-top: -47px !important;
}

@media (max-width: 1200px){
  .custom-info-panel{
    display: none !important;
  }
  
  .responsive-info-panel{
    display: block !important;
  }

  .map-content{
    margin-top: 30px;
  }

  .footer{
    height: 200px !important;
  }

  .footer-info {
    padding: 35px !important;  
  }

  .footer-info-content-1 {
    margin-left: 25px !important;
    margin-right: 25px !important;
    font-size: 15px !important;
    margin-top: 51px !important;
    text-align: center !important;
  }

  .footer-without-image .top-button {
    top: -42.5px !important;
  }
}