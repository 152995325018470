.menu-responsive-list{
  list-style: none;
  width: 100%;
  padding-left: 6px;
  margin-top: 6px;
}

.menu-responsive-list li{
  padding: 10px;
  border-bottom: 1px solid #ccc;
  font-size: 19px;
  height: 50px;
}